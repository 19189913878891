import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import {
  DISCUSSION,
  QUESTION,
  EXPERIMENT_ADD_ACTIONS_MENU_BY_CTA,
  EXPERIMENT_RECENT_ACTIVITY_CATS,
} from '@wix/communities-forum-client-commons';

import withExperiment from '../../hoc/with-experiment';
import CreatePostCta from '../create-post-cta';
import ItemContainer from '../post-list-mobile-common/item-container';
import NoPostsFiltered from '../no-posts-filtered';
import { HorizontalSeparator } from '../separator';
import { CategoryPageFiltersBar } from '../filters-bar-mobile';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import PaginationMobile from '../pagination-mobile';
import FollowCard from '../follow-card';
import PostListCard from '../card/post-list-card';
import { MoreIconCentered } from '../icons/more-icon';
import MoreButtonMobile, { SIZE_LARGE } from '../more-button-mobile/more-button-mobile';
import { MODAL_TYPE_CATEGORY_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import styles from './post-list-mobile.scss';

const PostListMobile = ({
  category,
  posts,
  postTypes,
  query,
  page,
  entityCount,
  buildPageUrl,
  onPageChange,
  onLikeClick,
  showCreatePostAction,
  showFollowCategoryAction,
  hasActiveFilter,
  showFilters,
  showMemberPosts,
  customCtaLabel,
  isNewActionsMenuEnabled,
  isRecentActivityEnabled,
}) => {
  const showCategoryActions = !!category?._id;

  return (
    <div>
      {showCreatePostAction && (
        <React.Fragment>
          {isNewActionsMenuEnabled ? (
            <div className={styles.buttonsContainer}>
              {showCategoryActions && (
                <div className={styles.actionsButtonWrapper}>
                  <MoreButtonMobile
                    center
                    size={SIZE_LARGE}
                    Icon={MoreIconCentered}
                    category={category}
                    showMobileActions
                    showMarkPostsAsRead
                    dataHook="category-more-button"
                    type={MODAL_TYPE_CATEGORY_ACTIONS}
                  />
                </div>
              )}
              <div className={styles.ctaButtonWrapper}>
                <CreatePostCta
                  noPadding
                  categorySlug={category.slug}
                  postTypes={postTypes}
                  createPostCtaLabel={customCtaLabel}
                />
              </div>
            </div>
          ) : (
            <ItemContainer>
              <CreatePostCta
                categorySlug={category.slug}
                postTypes={postTypes}
                createPostCtaLabel={customCtaLabel}
              />
            </ItemContainer>
          )}
          <HorizontalSeparator />
        </React.Fragment>
      )}
      {showFilters && <CategoryPageFiltersBar />}
      {!posts.length && hasActiveFilter && <NoPostsFiltered noMemberPosts={showMemberPosts} />}
      {posts.map((post, idx) => (
        <React.Fragment>
          <ItemContainer key={post?._id}>
            <PostListCard
              post={post}
              query={query}
              onLikeClick={onLikeClick}
              showRecentActivity={isRecentActivityEnabled}
              withContentExcerpt
            />
          </ItemContainer>
          {idx < posts.length - 1 ? <HorizontalSeparator /> : null}
        </React.Fragment>
      ))}
      <PaginationMobile
        page={page}
        entityCount={entityCount}
        showPerPage={POSTS_PER_PAGE}
        onChange={({ page }) => onPageChange(page)}
        buildPageUrl={buildPageUrl}
      />
      {(showCreatePostAction || showFollowCategoryAction) && <HorizontalSeparator />}
      {showCreatePostAction && (
        <React.Fragment>
          <ItemContainer>
            <CreatePostCta
              categorySlug={category.slug}
              postTypes={postTypes}
              createPostCtaLabel={customCtaLabel}
            />
          </ItemContainer>
        </React.Fragment>
      )}
      {showFollowCategoryAction && (
        <FollowCard withoutPaddingTop={showCreatePostAction} category={category} />
      )}
    </div>
  );
};

PostListMobile.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showFollowCategoryAction: PropTypes.bool,
  showFilters: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  onPageChange: PropTypes.func,
  buildPageUrl: PropTypes.func,
  showMemberPosts: PropTypes.bool,
  postTypes: PropTypes.arrayOf(PropTypes.oneOf([QUESTION, DISCUSSION])),
};

export default flowRight(
  withExperiment({
    isNewActionsMenuEnabled: EXPERIMENT_ADD_ACTIONS_MENU_BY_CTA,
    isRecentActivityEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
)(PostListMobile);
